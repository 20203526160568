import request from '@/utils/request'


// 查询用户注销业务列表
export function listUserLogout(query) {
  return request({
    url: '/platform/user-logout/list',
    method: 'get',
    params: query
  })
}

// 查询用户注销业务分页
export function pageUserLogout(query) {
  return request({
    url: '/platform/user-logout/page',
    method: 'get',
    params: query
  })
}

// 查询用户注销业务详细
export function getUserLogout(data) {
  return request({
    url: '/platform/user-logout/detail',
    method: 'get',
    params: data
  })
}

// 新增用户注销业务
export function addUserLogout(data) {
  return request({
    url: '/platform/user-logout/add',
    method: 'post',
    data: data
  })
}

// 修改用户注销业务
export function updateUserLogout(data) {
  return request({
    url: '/platform/user-logout/edit',
    method: 'post',
    data: data
  })
}

// 删除用户注销业务
export function delUserLogout(data) {
  return request({
    url: '/platform/user-logout/delete',
    method: 'post',
    data: data
  })
}
