<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="審核狀態" >
        <a-select v-model="form.applicationStatus" placeholder="请选择審核狀態" @change="changeStatus">
          <a-select-option :value="1">同意</a-select-option>
          <a-select-option :value="2">拒绝</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拒絕類型" v-if="showVer">
        <a-select v-model="form.refusalType" placeholder="请选择審拒絕類型">
          <a-select-option :value="1">拒絕申請</a-select-option>
          <a-select-option :value="2">用戶申訴取消注銷流程</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="拒绝原因" prop="refusalCause" v-if="showVer">
        <a-input v-model="form.refusalCause" type="textarea" allow-clear placeholder="请输入拒绝原因" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getUserLogout, addUserLogout, updateUserLogout } from '@/api/platform/userLogout'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      showVer: false,
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        type: null,

        reason: null,

        applicationStatus: 0,

        refusalType:1,

        refusalCause: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '$comment不能为空', trigger: 'blur' }
        ],
        type: [
          { required: true, message: '類型不能为空', trigger: 'change' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    changeStatus(status){
      if(status == 1){
        this.showVer = false;
      }
      if(status == 2){
        this.showVer = true;
      }
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        type: null,
        reason: null,
        applicationStatus: 0,
        refusalType:null,
        refusalCause: null,
        createTime: null,
        remark: null,
      }
    /*  this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getUserLogout({"id":id}).then(response => {
        this.form = response.data
        this.form.applicationStatus = 1;
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      let status = this.form.applicationStatus
      let refusalType = this.form.refusalType
      if(status == 2){
        if(!refusalType){
          this.$message.warning(
            '請選擇拒絕類型',
            3
          )
          return;
        }

      }
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateUserLogout(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addUserLogout(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
